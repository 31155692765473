<template>
  <div class="layout" :loading="loading">
    <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">Connections</h3>
    </div>
    <Alert
      v-if="alert.show"
      title="Attention needed"
      content="Please go to Stripe dashboard to finish the connection."
    />
    <div class="relative flex flex-col">
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div
            class="overflow-hidden border border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Status
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="connection in connections"
                  :key="connection.Id"
                  class="text-left"
                >
                  <td class="px-6 py-4 text-sm text-gray-500">
                    <span
                      class="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800"
                    >
                      <svg
                        class="-ml-1 mr-1.5 h-2 w-2 text-indigo-400"
                        fill="currentColor"
                        viewBox="0 0 8 8"
                      >
                        <circle cx="4" cy="4" r="3" />
                      </svg>
                      {{ connection.Name }}
                    </span>
                  </td>
                  <td class="px-6 py-4 text-sm">
                    <span
                      v-if="connection.Status === 'Pending'"
                      class="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
                    >
                      {{ connection.Status }}
                    </span>
                    <span
                      v-if="connection.Status === 'Active'"
                      class="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
                    >
                      {{ connection.Status }}
                    </span>
                  </td>
                  <td
                    class="flex justify-end space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <button
                      type="button"
                      @click="goTo"
                      class="focus:outline-none inline-flex items-center rounded-md border border-transparent border-purple-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      <ExternalLinkIcon
                        class="h-5 w-5 flex-shrink-0 text-purple-400"
                      />
                      <span class="ml-2">Go to Stripe</span>
                    </button>
                  </td>
                </tr>
                <tr class="text-left">
                  <td class="px-6 py-4 text-sm text-gray-500">Amazon</td>
                  <td class="px-6 py-4 text-sm">
                    <span
                      class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                    >
                      Coming Soon
                    </span>
                  </td>
                  <td
                    class="flex justify-end space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <button
                      type="button"
                      class="focus:outline-none inline-flex cursor-not-allowed items-center rounded-md border border-transparent border-purple-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 opacity-25 shadow-sm hover:text-purple-800 focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      <ExternalLinkIcon
                        class="h-5 w-5 flex-shrink-0 text-purple-400"
                      />
                      <span class="ml-2">Go to Amazon</span>
                    </button>
                  </td>
                </tr>
                <tr class="text-left">
                  <td class="px-6 py-4 text-sm text-gray-500">Shopify</td>
                  <td class="px-6 py-4 text-sm">
                    <span
                      class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                    >
                      Coming Soon
                    </span>
                  </td>
                  <td
                    class="flex justify-end space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <button
                      type="button"
                      class="focus:outline-none inline-flex cursor-not-allowed items-center rounded-md border border-transparent border-purple-300 px-3 py-2 text-sm font-medium leading-4 text-purple-400 opacity-25 shadow-sm hover:text-purple-800 focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      <ExternalLinkIcon
                        class="h-5 w-5 flex-shrink-0 text-purple-400"
                      />
                      <span class="ml-2">Go to Shopify</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import {
  ExternalLinkIcon,
  // ExclamationCircleIcon,
} from "@heroicons/vue/outline";
import API from "@/api";

import { directive } from "vue-tippy";
import "tippy.js/animations/scale.css";

import Alert from "@/common/components/alerts/index.vue";

export default {
  components: {
    Alert,
    ExternalLinkIcon,
    // ExclamationCircleIcon,
  },
  directives: {
    tippy: directive,
  },
  setup() {
    const connections = ref([]);
    const loading = ref(false);
    const alert = reactive({
      stripe: false,
    });

    const goTo = async () => {
      try {
        const response = await API.Organization.Connections.goTo();

        window.open(`${response.data.Item}`, "_blank");
      } catch (e) {
        console.error(e);
      }
    };

    onMounted(async () => {
      loading.value = true;
      try {
        let response = await API.Organization.Connections.get();
        connections.value = response.data.List;

        connections.value.forEach((connection) => {
          if (connection.Status === "Pending") {
            alert.stripe = true;
          }
        });

        loading.value = false;
      } catch (error) {
        loading.value = false;
      }
    });

    return {
      goTo,
      alert,
      connections,
    };
  },
};
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  /* overflow-y: scroll; */
  width: 100%;
  position: relative;
}
</style>
