<template>
  <div class="mb-4 rounded-md bg-yellow-50 p-4 text-left">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-yellow-800">{{ title }}</h3>
        <div class="mt-2 text-sm text-yellow-700">
          <p>
            {{ content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/solid";
export default {
  components: {
    ExclamationIcon,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
  setup() {},
};
</script>

<style lang="scss" scoped></style>
